/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('[data-toggle="offcanvas"]').click(function () {
          $('.row-offcanvas').toggleClass('active');
        });

        $('.collapse-transcript').on('shown.bs.collapse', function(e) {
          $(e.target).data('bs.collapse').$trigger.html('hide transcript');
        });
        $('.collapse-transcript').on('hidden.bs.collapse', function(e) {
          $(e.target).data('bs.collapse').$trigger.html('show transcript');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $.each($('#svg-circle-progress #bar'), function(){
          DASHBOARD.doStrokeOffset($(this));
        });
        // console.log($('#svg-circle-progress #bar').data('percent'));
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // Surveys.
    'single_survey': {
      init: function() {
        $('form.survey-form').validate({
          // debug: true,
          errorClass: 'alert alert-info',
          errorPlacement: function(error, element)
          {
              if ( element.is(":radio") )
              {
                  error.prependTo( element.parents('.panel-body') );
              }
              else
              { // This is the default behavior
                  error.insertAfter( element );
              }
          },
          // submitHandler: function (form) { // for demo
          //     alert('valid form');
          //     return false;
          // }
        });
      }
    },
    // Assessments
    'single_assessment': {
      init: function() {
        $('form.question-form').validate({
          errorClass: 'alert alert-info',
          errorPlacement: function(error, element) {
            if ( element.is(":radio") ) {
              error.prependTo( element.parents('.panel-body') );
            } else {
              error.insertAfter( element );
            }
          }
        });
      }
    },
    // Issues
    'single_issue': {
      init: function() {
        $('form.issue-question').validate({
          errorClass: 'alert alert-info',
          errorPlacement: function(error, element) {
            if ( element.is(":radio") ) {
              error.prependTo( element.parents('.panel-body') );
            } else {
              error.insertAfter( element );
            }
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
