var DASHBOARD = {
	doStrokeOffset: function( $circle ) {
		var percentage = $circle.data('percent');
		if (isNaN(percentage)) {
	    	percentage = 100;
		} else {
		    var r = $circle.attr('r');
		    var c = Math.PI * (r * 2);

		    if (percentage < 0) {
		        percentage = 0;
		    }
		    if (percentage > 100) {
		        percentage = 100;
		    }

		    var pct = ((100 - percentage) / 100) * c;

		    $circle.css({
		        strokeDashoffset: pct
		    });
		}
	}
};